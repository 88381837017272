import * as React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import VideoBg from "../components/videoBg";
import TextArrows from "../components/textArrows";

import ArrowBlank from "../svg/arrow_blank.svg";
import SliderNext from "../svg/slider-next.svg";
import SliderPrev from "../svg/slider-prev.svg";
import BottomLeftCorner from "../svg/bottom_left_corner.svg";
import BottomRightCorner from "../svg/bottom_right_corner.svg";
import Circle from "../svg/circle.svg";
import MainSectionLogo from "../svg/main_section_logo.svg";
import Scroller from "../svg/scroller.svg";
import TopLeftCorner from "../svg/top_left_corner.svg";
import TopRightCorner from "../svg/top_right_corner.svg";
import { FRONT_DOMAIN } from "../config";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import "../scss/main.scss";
import 'swiper/css';

const AboutPage = ({pageContext: {about}}) => {
    const [screen1, screen3, screen4, screen2] = about.items;

    React.useEffect(() => {
        const scrollContainer = document.querySelector('.site-wrapper');

        if(scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
    }, []);

    const AboutSlider = () => {
        const prevRef = React.useRef(null)
        const nextRef = React.useRef(null)

        return (

            <Swiper
                modules={[Navigation]}
                slidesPerView="auto"
                centeredSlides={true}
                loop={true}

                onInit={(swiper) => {
                    swiper.params.navigation.prevEl = prevRef.current;
                    swiper.params.navigation.nextEl = nextRef.current;
                    swiper.navigation.init();
                    swiper.navigation.update();
                }}
            >
                <SwiperSlide>
                    <img src="/static/img/about/1_2.jpg" alt=""/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="/static/img/about/1_1.jpg" alt=""/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="/static/img/about/1_3.jpg" alt=""/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="/static/img/about/1_4.png" alt=""/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="/static/img/about/1_5.jpg" alt=""/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="/static/img/about/1_6.jpg" alt=""/>
                </SwiperSlide>
                <div className="swiper-navigation-wrapper">
                    <div className="swiper-navigation-item swiper-navigation__prev" ref={prevRef}><SliderPrev/></div>
                    <div className="swiper-navigation-item swiper-navigation__next" ref={nextRef}><SliderNext/></div>
                </div>
            </Swiper>
        )
    }

    const AboutLargeSlider = () => {
        const prevRef = React.useRef(null)
        const nextRef = React.useRef(null)

        return (

            <Swiper
                modules={[Navigation]}
                slidesPerView={1}
                loop={true}

                onInit={(swiper) => {
                    swiper.params.navigation.prevEl = prevRef.current;
                    swiper.params.navigation.nextEl = nextRef.current;
                    swiper.navigation.init();
                    swiper.navigation.update();
                }}
            >
                <SwiperSlide>
                    <img src="/static/img/about/2_2.jpg" alt=""/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="/static/img/about/2_1.jpg" alt=""/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="/static/img/about/2_3.png" alt=""/>
                </SwiperSlide>
                <SwiperSlide>
                    <img src="/static/img/about/2_4.jpg" alt=""/>
                </SwiperSlide>
                <div className="swiper-navigation-wrapper">
                    <div className="swiper-navigation-item swiper-navigation__prev" ref={prevRef}><SliderPrev/></div>
                    <div className="swiper-navigation-item swiper-navigation__next" ref={nextRef}><SliderNext/></div>
                </div>
            </Swiper>
        )
    }

    return (
        <>
            <Helmet>
                <title>About | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <VideoBg />

            <div className="site-section__about-top-background"></div>

            <section id="main-section" className="site-section">
                <div className="content-section" style={ { "--width": "72rem" } }>
                    <MainSectionLogo className="content-section__logo" />

                    <h1
                        className="content-section__title"
                        dangerouslySetInnerHTML={{__html: screen1.name}}
                    >
                        {/* Voyage into the mesmerising intersection of&nbsp;art, science &amp;&nbsp;technology */}
                    </h1>

                    <div
                        className="content-section__text"
                        dangerouslySetInnerHTML={{__html: screen1.description}}
                    >
                        {/* <p>Welcome to&nbsp;TELEPORT - a&nbsp;parallel universe of&nbsp;mind-bending installations and tech-driven visual compositions.</p> */}
                    </div>
                </div>

                <button className="scroller site-section__scroller" type="button" onClick={ () => scrollTo("#teleport-universe") }>
                    <Scroller className="scroller__arrow" />
                </button>
            </section>





            <section id="teleport-universe" className="site-section site-section_universe">
                <div className="content-section" style={ { "--width": "146.8rem" } }>
                    <div className="corners corners_hide-corners">
                        <h2
                            className="content-section__title content-section__title_with-arrows"
                        >
                            WE TAKE YOU INTO TOMORROW WHERE  the boundaries between the real <nobr><TextArrows direction="left" count="4"/> and <TextArrows direction="right" count="4"/></nobr> the digital worlds <span className="content-section__title-blur">become blurred.</span>
                        </h2>



                        <TopLeftCorner className="corners__corner corners__corner_top-left" />
                        <TopRightCorner className="corners__corner corners__corner_top-right" />
                        <BottomRightCorner className="corners__corner corners__corner_bottom-right" />
                        <BottomLeftCorner className="corners__corner corners__corner_bottom-left" />
                    </div>
                    {/*<button className="scroller site-section__scroller" type="button" onClick={ () => scrollTo("#teleport-experiment") }>
                        <Circle className="scroller__circle" />
                        <Scroller className="scroller__arrow" />
                    </button>*/}
                </div>
            </section>

            <div className="site-section__about-slider">
                <div className="about-slider-wrapper">
                    <AboutSlider/>
                </div>
            </div>





            <section id="teleport-experiment" className="site-section">
                <div className="content-section" style={ { "--width": "72rem" } }>
                    <h1
                        className="content-section__title"
                        dangerouslySetInnerHTML={{__html: screen3.name}}
                    />

                    <div
                        className="content-section__text"
                        dangerouslySetInnerHTML={{__html: screen3.description}}
                    />
                    {/* <h2 className="content-section__title">An&nbsp;unprecedented multimedia experiment</h2>

                    <div className="content-section__text">
                        <p>TELEPORT proudly exhibits the awe-inspiring work of some of&nbsp;our planet&rsquo;s most progressive new media artists.</p>
                        <p>Thanks to&nbsp;their cross-disciplinary collaboration, TELEPORT&rsquo;s vast 5,000&nbsp;sq. foot exhibition space is&nbsp;transformed into a&nbsp;cinematic adventure that travels to&nbsp;the far-fetched future.</p>
                        <p>Their bewildering work, which spans light &amp;&nbsp;sound compositions, immersive projections, interactive installations &amp;&nbsp;fractal art, has all been designed exclusively for TELEPORT.</p>
                        <p>Prepare to&nbsp;be&nbsp;perplexed.</p>
                    </div> */}

                    <button className="scroller site-section__scroller" type="button" onClick={ () => scrollTo("#teleport-technology") }>
                        <Scroller className="scroller__arrow" />
                    </button>
                </div>
            </section>

            <div className="site-section__about-slider-large">
                <div className="about-slider-large-wrapper">
                    <AboutLargeSlider/>
                </div>
            </div>

                <section id="teleport-technology" className="site-section">
                    <div className="content-section" style={ { "--width": "80rem" } }>
                    <h1
                        className="content-section__title"
                        dangerouslySetInnerHTML={{__html: screen4.name}}
                    />

                    <div
                        className="content-section__text"
                        dangerouslySetInnerHTML={{__html: screen4.description}}
                    />
                        {/* <h2 className="content-section__title">A&nbsp;unique experience at&nbsp;the cutting edge of&nbsp;technology.</h2>

                    <div className="content-section__text">
                        <p>TELEPORT&rsquo;s ground-breaking entertainment space features one-of-a-kind sensory artworks that hypnotise and inspire.</p>

                        <p>Our featured artists &amp;&nbsp;partnered tech companies power their works with astounding advances in&nbsp;emerging technologies including Artificial Intelligence, Robotics, Augmented Reality, Computational Design &amp; 3D&nbsp;Hyper-real Sound.</p>
                    </div>

                    <button className="scroller site-section__scroller" type="button" onClick={ () => scrollTo("#get-tickets") }>
                        <Scroller className="scroller__arrow" />
                    </button>*/}

					<span className="ibrush-link">
                        <a href="https://ibrush.ru" target="_blank" rel="noreferrer">Website by&nbsp;iBRUSH</a>
                    </span>
                </div>
            </section>
{/*
            <section id="get-tickets" className="site-section">
                <div className="content-section">
                    <div className="corners">
                        <Link to="/tickets/" className="get-tickets">
                            Get your tickets now

                            <ArrowBlank className="get-tickets__arrow" />
                        </Link>

                        <TopLeftCorner className="corners__corner corners__corner_top-left" />
                        <TopRightCorner className="corners__corner corners__corner_top-right" />
                        <BottomRightCorner className="corners__corner corners__corner_bottom-right" />
                        <BottomLeftCorner className="corners__corner corners__corner_bottom-left" />
                    </div>


                </div>
            </section>*/}
        </>
    )
}

export default AboutPage;
